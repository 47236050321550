'use strict';

define('vb/private/stateManagement/fragmentExtension',[
  'vb/private/stateManagement/containerExtension',
  'vb/private/stateManagement/context/fragmentExtensionContext',
  'vb/private/constants',
  'vb/private/utils',
  'vb/helpers/mixin',
  'vb/private/stateManagement/fragmentHolderExtensionMixin',
  'vb/private/stateManagement/eventBehaviorMixin',
  'vb/private/stateManagement/fragmentModuleViewModel',
  'vb/private/events/eventMonitorOptions',
  'vb/private/log',
  'vbc/private/logConfig',
], (ContainerExtension, FragmentExtensionContext, Constants, Utils, Mixin, FragmentHolderExtensionMixin,
  EventBehaviorMixin, FragmentModuleViewModel, EventMonitorOptions, Log, LogConfig) => {
  const logger = Log.getLogger('/vb/stateManagement/fragmentExtension', [
    // Register a custom logger
    {
      name: 'coralInfo',
      severity: 'info',
      style: 'coral',
    },
    {
      name: 'beforeHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerStart,
    },
    {
      name: 'afterHandleEvent',
      severity: Constants.Severity.INFO,
      style: LogConfig.FancyStyleByFeature.containerEnd,
    },
  ]);
  /**
   * FragmentExtension class used when a fragment is extended from base. In addition the base fragment could contain
   * a dynamic container; and customer could extend fragment along with the dynamic-container template
   */
  class FragmentExtension extends Mixin(ContainerExtension)
    .with(FragmentHolderExtensionMixin, EventBehaviorMixin) {
    /**
     * A extension fragment can only reference a fragment defined in base extension. It's not possible for an
     * extension fragment to include 'local' fragments (local to the current extension). At the moment only new
     * pages / layout (under ui/self) in an extension can define and use local fragments.
     * Example for fragments defined under <ext-A>/.../ui/self/fragments,
     *   (1) extension fragment template defined under <ext-B>/.../ui/ext-A/.../fragment-template-x.html can reference
     *   fragment from ext-A or some upstream extension
     *   (2) new fragment in ext-B can reference ext-A fragments or local fragments. This class is not involved for
     *   a new fragment
     *
     * @constructor
     */
    constructor(extension, path, base, className = 'FragmentExtension') {
      super(extension, null, path, base, className);
      this.inputParams = base.inputParams;
    }

    static get extensionClass() {
      return FragmentExtension;
    }

    /**
     * @type {string}
     */
    static get resourceSuffix() {
      return '-fragment-x.json';
    }

    /**
     * The default event prefix is the lowercase class name (see container.js) but for
     * fragment extension we want to use the same event prefix as fragment
     *
     * @type {String}
     */
    // eslint-disable-next-line class-methods-use-this
    get eventPrefix() {
      return 'fragment';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor
     *
     * @type {String} the descriptor loader function name
     */
    static get descriptorLoaderName() {
      return 'getFragmentExtensionDescriptor';
    }

    /**
     * The name of the runtime environment function to be used to load the module functions.
     *
     * @type {String} the module loader function name
     */
    static get functionsLoaderName() {
      return 'getFragmentExtensionFunctions';
    }

    /**
     * The name of the runtime environment function to be used to load the descriptor.
     * @type {string}
     */
    get fullName() {
      return `${this.id}-fragment`;
    }

    /**
     * Returns the ExtensionContext constructor used to create the '$' expression context.
     * @type {FragmentExtensionContext}
     */
    static get ContextType() {
      return FragmentExtensionContext;
    }

    /**
     * The resourceName can be different from the name (which generally is the 'id'). Container, when it loads a
     * specific resource must use this property as the actual name of the resource that this fragment represents.
     * Example: fragment id is unique foo1/foo2 etc. Each instance of fragment loads the same resource 'foo-fragment.*'
     * @type {String}
     */
    get resourceName() {
      return this.base.resourceName;
    }

    /**
     * when a fragment variable is marked as writable add a valueChanged listener so that the CCA can be notified of
     * the value change. This is explicitly wired as fragment params are not direct properties on the vb-fragment CCA
     * value changes for whatever reason is not getting written back automatically.
     * @param {Variable} newVariable
     * @param {Object} onValueChangedDef the valueChanged def on the variable
     * @param availableContexts
     */
    addValueChangedListeners(newVariable, onValueChangedDef, availableContexts) {
      const def = newVariable.descriptor;
      // only variables marked writeback:true and input:fromCaller are written back
      if (def && def[Constants.VariableProperties.WRITEBACK]
        && def[Constants.VariableProperties.INPUT] === Constants.VariablePropertyInput.FROM_CALLER) {
        this.handleValueChangedListenerForWritable(newVariable, onValueChangedDef, availableContexts);
      }
      super.addValueChangedListeners(newVariable, onValueChangedDef, availableContexts);
    }

    /**
     * custom valueChanged listener that writes back value on fragment CCA mapped variable
     * @param newVariable
     * @param onValueChangedDef
     * @param availableContexts
     */
    handleValueChangedListenerForWritable(newVariable, onValueChangedDef, availableContexts) {
      if (!newVariable.onValueChanged) {
        this.log.warn(newVariable.name, 'does not have a live expression so its onValueChanged listener is ignored');
      }
      const varChangeTracker = {
        eventSource: newVariable.onValueChanged,
        eventListener: (e) => {
          const eventPayload = e || {};
          const context = availableContexts.clone();
          context[Constants.ContextName.EVENT] = e; // capture event payload on the scope
          const eventName = e.type; // vb event type and name is the same
          logger.beforeHandleEvent(this.className, this.id,
            'handling variable event', eventName, 'with payload:', e);
          const mo = new EventMonitorOptions(EventMonitorOptions.SPAN_NAMES.EVENT_VARIABLE, eventName, e, this);
          return this.log.monitor(mo, (eventTime) => {
            // notify the outer context of the value change in current fragment (scope). The container is at most
            // privy to some methods on the fragment bridge, like valueChanged
            const parentBridge = this.parent.fragmentBridge;
            try {
              parentBridge.valueChanged(Object.assign({}, eventPayload, { _vb_fragmentId: this.id }));
              logger.afterHandleEvent(this.className, this.id,
                'handled variable event', eventName, 'successfully', eventTime());
            } catch (error) {
              logger.afterHandleEvent('Failed to handle variable event', eventName, eventTime(error));
              this.log.error(error);
            }
          });
        },
      };
      newVariable.onValueChanged.add(varChangeTracker.eventListener, this);
      this.variablesListeners.push(varChangeTracker);
    }

    /**
     * This is called by ConfigurableMetadataProviderHelper._createExtensionModels when there is
     * dynamic container in the FragmentExtension.
     *
     * @returns {Promise<Object>} Returns an instance of FragmentModuleViewModel instance.
     */
    getViewModel() {
      return Promise.resolve()
        .then(() => new FragmentModuleViewModel(this));
    }

    /**
     * Returns a scope resolver map where keys are scope name ("fragment")
     * and value the matching objects. This is used to build the scopeResolver object.
     *
     * @private
     * @return {Object} an object which properties are scope
     */
    getScopeResolverMap() {
      return {
        [Constants.FRAGMENT_PREFIX]: this,
      };

      // TODO: unclear how to setup namespace prefixes for application and global
      // return {
      //  [Constants.GLOBAL_PREFIX]: this,
      //  [Constants.APPLICATION_PREFIX]: this,
      // };

      // // if the parent of the FragmentExtension is defined we need to merge scope resolvers
      // // so that the FragmentExtension has access to its parent's scopes
      // if (this.parent) {
      //   return Object.assign(map, this.parent.getScopeResolverMap());
      // }
      //
      // return map;
    }

    /**
     * Used by event processing. For FragmentExtension containers, we start (and end) with the Fragment itself.
     * @see FireCustomEventAction
     *
     * @overrides Container.getLeafContainer
     * @returns {Fragment}
     * @overrides
     */
    // eslint-disable-next-line class-methods-use-this
    getLeafContainer() {
      return this.base;
    }

    /**
     * delegates to base
     * @param options
     * @return {string|undefined}
     */
    // eslint-disable-next-line class-methods-use-this
    validateNavigation(options) {
      return this.base.validateNavigation(options);
    }

    /**
     * the extension could have a template that references another fragment!
     */
    dispose() {
      Object.keys(this.fragments).forEach((fragmentId) => {
        const frag = this.fragments[fragmentId];
        frag.dispose();
      });

      delete this.definition;

      // dispose the scope associated to the fragment extension, from the store.
      super.dispose();
    }
  }

  return FragmentExtension;
});

